@font-face {
  font-style: normal;
  font-weight: normal;
  font-family: 'zef-icons-full';
  font-display: block;
  src: url('./fonts/zef-icons-full.woff2?oiu12h') format('woff2'),
    url('./fonts/zef-icons-full.ttf?oiu12h') format('truetype'),
    url('./fonts/zef-icons-full.woff?oiu12h') format('woff'),
    url('./fonts/zef-icons-full.svg?oiu12h#zef-icons-full') format('svg');
}

.zef-icons-full {
  speak: none;

  /* use !important to prevent issues with browser extensions that change fonts */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'zef-icons-full' !important;
  line-height: 1;
  text-transform: none;
  letter-spacing: 0;

  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
}

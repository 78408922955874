@use 'sass:math';

@function parse-align($align) {
  $align: if($align == start, flex-start, $align);
  $align: if($align == end, flex-end, $align);
  $align: if($align == around, space-around, $align);
  $align: if($align == between, space-between, $align);

  @return $align;
}

@mixin z-fx-place-content($main-axis, $cross-axis) {
  $main-axis: parse-align($main-axis);
  $cross-axis: parse-align($cross-axis);

  &.z-fx,
  &.z-fx-col {
    align-items: $cross-axis;
    justify-content: $main-axis;
  }
}
@mixin z-fx-gap($gap) {
  $gap: #{$gap}px;

  &.z-fx {
    &:not(.z-fx-reverse) {
      > * + * {
        margin-left: $gap;
      }
    }

    &.z-fx-reverse {
      > * + * {
        margin-right: $gap;
      }
    }
  }

  &.z-fx-col {
    &:not(.z-fx-reverse) {
      > * + * {
        margin-top: $gap;
      }
    }

    &.z-fx-reverse {
      > * + * {
        margin-bottom: $gap;
      }
    }
  }
}

.z-fx,
.z-fx-row,
.z-fx-col {
  display: flex;
  box-sizing: border-box;
}

.z-fx,
.z-fx-row {
  > .z-fx-auto,
  > .z-fx-grow,
  > .z-fx-flex {
    min-width: 0;
  }

  &.z-fx-reverse {
    flex-direction: row-reverse;
  }
}

.z-fx-inline {
  display: inline-flex;
}

.z-fx-col {
  flex-direction: column;
  min-height: 0;

  > .z-fx-auto,
  > .z-fx-grow,
  > .z-fx-flex {
    min-height: 0;
  }

  &.z-fx-reverse {
    flex-direction: column-reverse;
  }
}

.z-fx-wrap {
  flex-wrap: wrap;
}

$main-align: start, center, end, around, between, baseline;
$cross-align: append($main-align, stretch);

@each $main in $main-align {
  @each $cross in $cross-align {
    .z-fx-#{$main}-#{$cross} {
      @include z-fx-place-content($main, $cross);
    }
  }
}

$max-gap: 128;

.z-fx-gap-2 {
  @include z-fx-gap(2);
}

.z-fx-gap-4 {
  @include z-fx-gap(4);
}

.z-gap-2 {
  gap: 2px;
}

.z-gap-4 {
  gap: 4px;
}

@for $i from 1 through math.div($max-gap, 8) {
  $gap: $i * 8;

  .z-fx-gap-#{$gap} {
    @include z-fx-gap($gap);
  }

  .z-gap-#{$gap} {
    gap: #{$gap}px;
  }
}

// Flex Child

@mixin z-fx-flex($flex) {
  box-sizing: border-box;
  flex: $flex;
}

.z-fx-grow {
  @include z-fx-flex(1 1 100%);
}

.z-fx-flex {
  @include z-fx-flex(1 1 0%);
}

.z-fx-2-1 {
  @include z-fx-flex(2 1 0%);
}

.z-fx-3-1 {
  @include z-fx-flex(3 1 0%);
}

.z-fx-auto {
  @include z-fx-flex(1 1 auto);
}

.z-fx-50 {
  @include z-fx-flex(1 1 100%);

  max-width: 50%;
}

.z-fx-none {
  @include z-fx-flex(0 0 auto);
}

.z-fx-no-shrink {
  flex-basis: auto;
  flex-shrink: 0;
}

.z-fx-no-grow {
  flex-basis: auto;
  flex-grow: 0;
}

.z-fx-initial {
  @include z-fx-flex(0 1 0%);
}

.z-fx-h100 {
  height: 100%;
}

.z-fx-w100 {
  width: 100%;
}

.z-fx-fill {
  @include z-fx-flex(1 1 auto);

  &.z-fx {
    width: 100%;
  }

  &.z-fx-col {
    height: 100%;
  }
}

$flex-align: start center end baseline stretch;

@each $align in $flex-align {
  .z-fx-flex-#{$align} {
    align-self: parse-align($align);
  }
}

.z-fx-flex-right {
  margin-left: auto !important;
}

.z-fx-flex-left {
  margin-right: auto !important;
}

.z-fx-min-width {
  min-width: 0;
}

.z-fx-min-height {
  min-height: 0;
}

.z-fx-full-height {
  margin-bottom: 0;
}

// shortcut classes

.z-fx-sc {
  @extend .z-fx;
  @extend .z-fx-start-center;
}

.z-fx-bc {
  @extend .z-fx;
  @extend .z-fx-between-center;
}

.z-fx-overflow-hidden {
  overflow: hidden;
}

.z-fx-fit-content {
  width: fit-content;
  max-width: fit-content;
}

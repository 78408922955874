@use 'colors' as *;
@use 'linda' as *;

@mixin tooltip-color($palette) {
  $color: map-get($zef-palettes, $palette);

  .mat-tooltip.zef-tooltip-#{$palette} {
    color: zef-contrast($color);
    background: zef-color($color);
  }

  &-right {
    .mat-tooltip.zef-tooltip-#{$palette}::before {
      border-right-color: zef-color($color);
    }
  }

  &-left {
    .mat-tooltip.zef-tooltip-#{$palette}::before {
      border-left-color: zef-color($color);
    }
  }

  &-above {
    .mat-tooltip.zef-tooltip-#{$palette}::before {
      border-top-color: zef-color($color);
    }
  }

  &-below {
    .mat-tooltip.zef-tooltip-#{$palette}::before {
      border-bottom-color: zef-color($color);
    }
  }
}

.mat-tooltip-panel {
  .mat-tooltip {
    overflow: visible;
    max-width: 296px;
    padding: 5px 8px;
    border-radius: 5px;

    font-size: 10px;
    font-weight: 600;
    text-align: center;
    color: zef-contrast($ink);
    background: zef-color($ink);

    &.hide {
      visibility: hidden;
    }

    &.zef-auto-width {
      max-width: fit-content;

      white-space: nowrap;
    }

    .tooltip-content {
      padding: 5px 8px;
      margin: -5px -8px;
      border-radius: 8px;

      color: inherit;
      background: inherit;
      box-shadow: 0 1px 4px 0 rgba(zef-color($ink), 0.2);

      > * {
        position: relative;
      }
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;

      width: 16px;
      height: 16px;

      pointer-events: none;
      background: inherit;
      box-shadow: -1px -1px 2px -1px rgba(zef-color($ink), 0.2);
    }
  }

  &-below,
  &-above {
    .mat-tooltip::before {
      left: 50%;

      transform: translateX(-50%) rotate(45deg);
    }
  }

  &-right,
  &-left {
    .mat-tooltip::before {
      top: 50%;

      transform: translateY(-50%) rotate(45deg);
    }
  }

  &-right {
    .mat-tooltip::before {
      left: -4px;
    }
  }

  &-left {
    .mat-tooltip::before {
      right: -4px;
    }
  }

  &-above {
    .mat-tooltip::before {
      bottom: -4px;
    }
  }

  &-below {
    .mat-tooltip::before {
      top: -4px;
    }
  }

  @include tooltip-color(primary);
  @include tooltip-color(success);
  @include tooltip-color(warning);
  @include tooltip-color(smart);
  @include tooltip-color(ink);
  @include tooltip-color(ink-lighter);
  @include tooltip-color(space);
  @include tooltip-color(white);
}

@use 'colors' as *;
@use 'linda' as *;

$items: accent, primary, logic, scoring, trigger;

@mixin sliderColor($name, $color, $variant: default) {
  &.mat-#{$name} {
    .mat-slider-track-fill {
      background-color: zef-color($color, $variant);
    }

    .mat-slider-thumb {
      &::after {
        background-color: zef-color($color, $variant);
      }
    }
  }

  &.range-slider {
    &.mat-#{$name} {
      .mat-slider-track-fill {
        background-color: zef-color($color, light);
      }
    }
  }
}

.mat-slider {
  .mat-slider-track-background {
    background-color: zef-color($space, darkest) !important;
  }

  .mat-slider-thumb {
    right: -16px;
    bottom: -16px;

    width: 32px;
    height: 32px;
    border: 2px solid transparent !important;

    background-color: transparent;

    &::after,
    &::before {
      content: '';
      position: absolute;

      border-radius: 100%;
    }

    &::before {
      top: 0;
      left: 0;

      width: 28px;
      height: 28px;

      background-color: zef-color($secondary);

      @include linda-shadow(1);
    }

    &::after {
      top: 6px;
      left: 6px;

      width: 16px;
      height: 16px;
    }
  }

  @each $item in $items {
    $palette: map-get($zef-palettes, $item);

    @include sliderColor($item, $palette);
  }

  @include sliderColor('ink-lighter', $ink, lighter);

  &.initial {
    .mat-slider-track-fill {
      background-color: zef-color($ink, lighter) !important;
    }
  }

  &.zef-slider-hidden-track {
    .mat-slider-track-fill {
      height: 2px !important;
      margin-top: 1px;

      background-color: zef-color($space, darkest) !important;
    }
  }
}

@use 'colors' as *;

.zef-scroll {
  overflow: auto;
}

.zef-scroll-x {
  overflow-x: auto;
}

.zef-scroll-y {
  overflow-y: auto;
}

.zef-scroll,
.zef-scroll-x,
.zef-scroll-y {
  position: relative;

  scrollbar-color: transparentize(zef-color($ink), 0.9) transparentize(zef-color($ink), 0.95);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;

    background-color: transparent;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    border-radius: 8px;

    background: transparentize(zef-color($ink), 0.9);
    background-clip: padding-box;

    &:hover {
      background-color: transparentize(zef-color($ink), 0.8);
    }
  }

  &::-webkit-scrollbar-track {
    border: 4px solid transparent;
    border-radius: 8px;

    background: transparentize(zef-color($ink), 0.95);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

ng-scrollbar:not(.z-perfect-scroll) {
  --scrollbar-size: 8px;
  --scrollbar-padding: 4px;
  --scrollbar-track-color: #{transparentize(zef-color($ink), 0.95)};
  --scrollbar-thumb-color: #{transparentize(zef-color($ink), 0.9)};
  --scrollbar-track-transition: none;
  --scrollbar-thumb-transition: 50ms background ease-in-out;

  &.auto-height {
    .ng-scroll-viewport {
      display: block;
    }

    .ng-scroll-content {
      flex: none;
    }
  }

  &.scroll-content-flex {
    > .ng-scrollbar-wrapper > .ng-scroll-viewport-wrapper > .ng-scroll-viewport > .ng-scroll-content {
      display: flex;
    }
  }

  .ng-scroll-viewport {
    display: flex;
    flex-direction: column;
  }

  .ng-scroll-content {
    flex: 1;

    vertical-align: middle;
  }

  .ng-scrollbar-wrapper {
    &[verticalhovered='true'] {
      .ng-scrollbar-thumb {
        background-color: transparentize(zef-color($ink), 0.8);
      }
    }

    &[verticalused='true'][horizontalused='true']::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;

      width: var(--scrollbar-total-size);
      height: var(--scrollbar-total-size);

      background-color: transparentize(zef-color($ink), 0.95);
    }

    > .scrollbar-control {
      border: 0 solid transparent;

      background-color: transparent;
    }
  }
}

@use 'colors' as *;

.mat-radio {
  &-ripple {
    display: none;
  }

  &-label {
    height: 32px;

    &-content {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &-container,
  &-outer-circle,
  &-inner-circle {
    width: 24px;
    height: 24px;
  }

  &-outer-circle {
    border-color: zef-color($space, darkest);

    background-color: zef-color($secondary);
  }

  &-checked {
    @each $palette, $color in getPalettes((primary, warning, alert, promo, logic, success, scoring, trigger)) {
      &.mat-#{$palette} {
        .mat-radio-outer-circle {
          border-color: zef-color($color) !important;
        }

        .mat-radio-inner-circle {
          background-color: zef-color($color);
        }
      }
    }
  }
}

.mat-radio-button.zef-input-error .mat-radio-outer-circle {
  border-color: zef-color($alert);
}

.mat-radio-button.text-indent {
  .mat-radio-label-content {
    margin-top: -4px;
    margin-left: 8px;
  }

  .mat-radio-label {
    display: flex;
    align-items: flex-start;
    height: auto;

    white-space: normal;
  }
}

@use 'colors' as *;
@use 'linda' as *;

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(4px);
  }

  75% {
    transform: translateX(-4px);
  }

  100% {
    transform: translateX(0);
  }
}

.auth-debugger {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;

  margin: 2rem;

  pointer-events: none;
  color: red;
}

.auth-content-wrapper {
  padding: 48px;
}

.auth-sidenav-box {
  position: relative;

  height: 100%;

  .close-button {
    position: absolute;
    z-index: 1;
    top: 8px;
    right: 8px;
  }
}

.auth-content-container {
  @include linda-container-card;
  width: 320px;
  padding: 48px;
}

.auth-input-field {
  height: 40px !important;
  border: 1px solid zef-color($space, darkest);

  background-color: zef-color($space, default);

  &.auth-input-error,
  &.auth-error-shake {
    border-color: zef-color($alert, default) !important;
  }

  &.auth-input-shadow {
    background-color: zef-color($space, dark);
    box-shadow: inset 0 1px 2px 0 rgba(17, 37, 57, 0.2);
  }
}

.auth-error-text {
  color: zef-color($alert, default);
}

.auth-error-shake {
  animation: shake 250ms ease-in-out 0ms 2;
}

.auth-sidebar-container {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  padding: 48px;

  background-color: zef-color($space, dark);
}

.auth-sidebar-content {
  width: 100%;
}

.auth-logo-link {
  width: 100%;
  margin-left: -16px;

  text-align: center;
}

.auth-or-divider {
  display: flex;
  margin: 16px 0;

  span {
    flex: 0 0 auto;
    margin: 0 32px;

    text-transform: uppercase;
    color: zef-color($ink, lightest);
  }

  hr {
    flex: 1 1 auto;
    border: none;
    border-bottom: 2px solid zef-color($space, darkest);
  }
}

.auth-provider-button {
  display: flex;

  img {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
  }
}

/* ZEF common styles */

@use 'colors' as *;
@use 'mixins' as *;
@use 'linda' as *;

.zef-visual {
  // LAYOUT CLASSES

  .zef-fit {
    min-width: 0;
  }

  .zeficons {
    flex: none;
  }

  .zef-tiny {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    margin: 0;

    &.mat-icon {
      font-size: 16px;
      line-height: 16px;
    }
  }

  .zef-small {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    margin: 0;
  }

  .zef-medium {
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
    max-width: 64px;
    max-height: 64px;
  }

  .zef-large {
    width: 96px;
    height: 96px;
    min-width: 96px;
    min-height: 96px;
    max-width: 96px;
    max-height: 96px;
  }

  .zef-huge {
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
    max-width: 120px;
    max-height: 120px;
  }

  // GENERIC CLASSES

  .zef-content-box {
    box-sizing: border-box;
    width: 100%;
    min-width: 0 !important;
    max-width: 1200px !important;
    margin: 0 auto !important;
  }

  .zef-content-container {
    width: 100%;
    min-height: 0;
    max-width: 960px;
    margin: 0 auto;
  }

  .zef-no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
  }

  .zef-tag {
    display: inline-flex;
    overflow: hidden;
    align-items: center;
    height: 32px;
    max-width: 100%;
    padding: 0 8px;
    margin: 4px;
    border-radius: 5px;
    outline: none;

    color: zef-color($ink, light);
    background: zef-color($space, darkest);
  }

  .zef-pill {
    box-sizing: border-box;
    height: 24px;
    padding: 4px;
    border: 1px solid zef-color($space, darkest);
    border-radius: 5px;

    font-size: 11px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

    color: zef-color($ink, lighter);
    background-color: zef-color($space);

    &.z-blue {
      color: zef-color($primary);
      background-color: zef-color($primary, lighter);
    }
  }

  .zef-logo-image {
    position: relative;

    overflow: hidden;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border: 1px solid zef-color($space, darkest);
    border-radius: 10px;

    object-fit: cover;
    background-size: cover;
    background-color: zef-color($secondary);
    background-repeat: no-repeat;
    background-position: center center;

    &.round {
      border-radius: 50%;
    }

    &.square {
      width: 50px;
      min-width: 50px;
    }

    &.rectangle {
      width: 40px;
      min-width: 40px;
    }
  }

  .zef-avatar-image {
    overflow: hidden;
    width: 60px;
    height: 60px;
    max-width: 60px;
    max-height: 60px;
    border: 1px solid zef-color($ink, lightest);
    border-radius: 50%;

    background-size: contain;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .zef-logo-image,
  .zef-avatar-image {
    &.zef-mini {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      max-width: none;
      max-height: none;
      margin: 0;

      &.zef-rect {
        height: 30px;
      }
    }

    &.zef-tiny {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      max-width: none;
      max-height: none;
      margin: 0;

      &.zef-rect {
        height: 40px;
      }
    }

    &.zef-small {
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;

      max-width: none;
      max-height: none;
    }

    &.zef-medium {
      width: 60px;
      height: 60px;
      min-width: 60px;
      min-height: 60px;

      max-width: none;
      max-height: none;
    }

    &.zef-large {
      width: 96px;
      height: 96px;
      min-width: 96px;
      min-height: 96px;

      max-width: none;
      max-height: none;
    }

    &.zef-huge {
      width: 120px;
      height: 120px;
      min-width: 120px;
      min-height: 120px;

      max-width: none;
      max-height: none;
    }
  }

  [class*='zef-web-button-'] {
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 0 24px;

    border: 2px solid;
    border-radius: 3px;

    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    font-family: Inter, sans-serif;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .zef-web-button-primary {
    border-color: zef-color($primary);

    color: white;
    background: zef-color($primary);
  }

  .zef-web-button-secondary {
    border-color: black;

    color: black;
    background: linear-gradient(180deg, rgba(white, 1) 0%, rgba(black, 0.05) 100%);
  }

  .zef-web-button-stealth {
    border-color: black;

    color: white;
    background: zef-color($ink);
  }

  .zef-text-link {
    &:hover {
      cursor: pointer;
    }
  }

  span + .zef-hyper-link {
    margin-left: 0.25rem;
  }

  .zef-hyper-link {
    border: 0;

    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    color: zef-color($primary);
    background: none;

    &.disabled {
      opacity: 0.3;

      pointer-events: none;
    }
  }

  .zef-rounded {
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .zef-half-rounded {
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .zef-foreground {
    margin: 16px;
    border-radius: 10px;

    background-color: rgba(white, 0.95);
  }

  .zef-border-darkest {
    border: 1px solid zef-color($space, darkest);
  }

  .zef-border-none {
    border: none !important;
  }

  .zef-input-status {
    padding: 0 4px;
  }

  .zef-route-spinner {
    position: fixed;
    z-index: 104;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(#000, 0.1);

    .mat-spinner {
      z-index: 99;
    }
  }

  .zef-route-container {
    position: relative;

    width: 100%;
    min-height: 0;

    &-wrapper {
      box-sizing: border-box;
      width: 100%;
      min-height: 0;
      max-width: 1024px;
      padding: 0 32px 32px;
      margin: 0 auto;
    }
  }

  .container-box {
    min-height: 0;
  }

  .zef-truncate-text {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .zef-image-upload {
    .dropzone {
      position: relative;

      &.dz-wrapper {
        &.dz-single {
          overflow: hidden;

          .dz-message {
            border: none;

            &.dz-placeholder {
              overflow: hidden;
              min-width: 96px;
              min-height: 96px;
              max-width: 96px;
              max-height: 96px;
              margin: 0;

              .dz-text {
                top: 50%;
                right: 0;
                left: 0;

                padding: 60px 16px;
              }

              .dz-image {
                width: 96px;
                height: 96px;
              }
            }
          }
        }
      }
    }

    &.zef-avatar-image {
      .dropzone {
        &.dz-wrapper {
          &.dz-single {
            overflow: hidden;

            .dz-message {
              border: none;

              &.dz-placeholder {
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    &.zef-avatar-image,
    &.zef-logo-image {
      .dropzone {
        &.dz-wrapper {
          &.dz-single {
            &::before {
              content: '';
              position: absolute;
              z-index: 1;

              display: block;

              background-color: transparentize(zef-color($ink, default), 1);

              transition: background-color 250ms ease-out;

              inset: 0;
            }

            .dz-message {
              .dz-text {
                display: none;
              }

              .dz-image {
                filter: none;
              }
            }

            .zef-upload-icon {
              position: absolute;
              z-index: 2;

              opacity: 0;

              pointer-events: none;
              color: #fff;

              transition: opacity 250ms ease-out;
            }

            &:hover {
              &::before {
                background-color: transparentize(zef-color($ink, default), 0.5);
              }

              .zef-upload-icon {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  // MODIFIER CLASSES

  .mat-title,
  .mat-headline,
  .mat-subheading-1,
  .mat-subheading-2 {
    &.zef-title {
      font-weight: 800;
    }

    &.zef-header {
      font-weight: 300;
    }
  }

  .mat-title {
    &.zef-title {
      font-size: 13px;
      text-transform: uppercase;
    }

    &.zef-header {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .mat-headline {
    &.zef-title {
      font-size: 20px;
    }

    &.zef-header {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }
  }

  .mat-toolbar {
    &.zef-primary {
      padding: 0 4px;
    }

    &.zef-secondary {
      min-height: 48px;
      padding: 0 4px;

      .mat-title {
        padding: 0 36px;
      }

      .mat-toolbar-row {
        min-height: 48px;
      }
    }

    &.zef-floating {
      position: fixed;
      z-index: 1;
      top: 56px;
      right: 0;
      left: 0;

      padding: 0 24px;
      border-bottom: solid 1px zef-color($ink, default);

      color: #fff;
      background-color: zef-color($ink, default);
    }

    .mat-raised-button {
      &.zef-cta-button {
        height: 36px;
        min-width: 132px;

        font-weight: 600;
      }
    }
  }

  .mat-tab-group,
  .mat-tab-nav-bar {
    &.zef-primary {
      .mat-tab-links {
        .mat-tab-link {
          margin: 0 4px;

          font-weight: bold;

          .mat-icon {
            margin-left: 0;
          }
        }
      }

      &:not(.zef-secondary) {
        .mat-ink-bar {
          display: none;
        }
      }
    }

    &.zef-secondary {
      padding: 0 16px;

      .mat-ink-bar {
        height: 4px;
        margin-bottom: -1px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        background: zef-color($ink, default);
      }

      .mat-tab-links {
        .mat-tab-link {
          font-weight: bold;
        }
      }

      &:not(.zef-primary) {
        .mat-tab-link {
          font-size: 12px;
          text-transform: uppercase;
        }
      }
    }

    &.zef-breadcrumbs {
      padding: 0 16px;

      .mat-tab-links {
        .mat-tab-link {
          opacity: 1;

          min-width: initial;
          padding: 0;
          margin: 0 16px;

          font-weight: 600;

          &.incomplete {
            opacity: 0.3;
          }

          &.active {
            opacity: 1;

            color: zef-color($primary, default);
          }

          .mat-icon {
            margin-left: 0;
          }
        }

        .divider-arrow {
          opacity: 0.3;
        }
      }

      .mat-ink-bar {
        height: 4px;
        margin-bottom: 0;
        border-radius: 10px 10px 0 0;

        background-color: zef-color($primary, default);
      }
    }

    &:not(.zef-primary, .zef-secondary, .zef-breadcrumbs) {
      .mat-tab-list,
      .mat-tab-labels {
        .mat-ink-bar {
          display: none;
        }
      }
    }
  }

  .mat-tab-group {
    &:not(.zef-primary, .zef-secondary) {
      .mat-tab-list {
        .mat-tab-labels {
          .mat-tab-label {
            opacity: 1;
            height: 32px;
            border: 1px solid zef-color($space, darkest);

            font-size: 13px;
            font-weight: 600;
            line-height: 32px;
            color: zef-color($ink);
            background: #fff linear-gradient(180deg, transparent 0%, rgba(zef-color($ink), 0.1) 100%);

            &:hover,
            &.active,
            &.mat-tab-label-active,
            &.cdk-focused {
              color: zef-color($primary);
            }

            &.active,
            &.mat-tab-label-active {
              @include linda-shadow(-1);

              border-bottom: none;

              background: zef-color($space, darker);
            }
          }
        }
      }
    }
  }

  .mat-tab-nav-bar {
    &:not(.zef-primary, .zef-secondary, .zef-breadcrumbs) {
      .mat-tab-links {
        .mat-tab-link {
          height: 32px;
          border: 1px solid zef-color($space, darkest);

          line-height: 32px;
          background: zef-color($space, darkest);

          &.active,
          &.mat-tab-label-active {
            border-bottom: none;

            background: transparent;
          }
        }
      }
    }
  }

  .mat-form-field {
    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-prefix,
        .mat-form-field-suffix {
          .mat-icon {
            &[fontset='zeficons'] {
              font-size: 24px;
            }
          }
        }
      }
    }

    // TODO: Material is missing class for these:
    // https://github.com/angular/material2/issues/3984
    &.zef-boxed-input {
      background-color: zef-color($space, dark);
    }

    &.zef-unboxed-input {
      border-color: transparent;

      &:hover,
      &.mat-focused {
        border-color: zef-color($primary, default);
      }
    }

    &.zef-default-input-big {
      height: 48px;

      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }

    &.zef-search-input {
      border-width: 1px;
      border-radius: 30px;

      background-color: #fff;

      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-prefix {
            .mat-select {
              border-top-left-radius: 30px;
              border-bottom-left-radius: 30px;
            }
          }

          .mat-form-field-suffix {
            .mat-select {
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
            }
          }
        }
      }
    }

    &.zef-align-top {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          align-items: start;

          .mat-form-field-prefix,
          .mat-form-field-suffix {
            margin-top: 2px;
          }

          .mat-form-field-infix {
            .mat-form-field-label-wrapper {
              .mat-form-field-label {
                top: 18px;
              }
            }
          }
        }
      }
    }

    &.zef-align-center {
      .mat-form-field-element {
        text-align: center;
      }

      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-infix {
            .mat-input-element {
              text-align: center;
            }

            .mat-form-field-label-wrapper {
              text-align: center;

              .mat-form-field-label {
                left: 0;

                width: 100%;
              }
            }
          }
        }
      }
    }

    &.zef-align-start {
      .mat-form-field-element {
        text-align: start;
      }

      .mat-form-field-label-wrapper {
        text-align: start;
      }
    }

    &.zef-align-end {
      .mat-form-field-element {
        text-align: end;
      }

      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-infix {
            .mat-form-field-label-wrapper {
              text-align: end;

              .mat-form-field-label {
                left: -12px;
              }
            }
          }
        }
      }
    }
  }

  // OVERLAY ITEMS CLASSES

  .zef-select-menu {
    &.mat-menu-panel {
      padding: 5px 0;

      .mat-menu-content {
        .mat-menu-item {
          height: 30px;
          min-width: 268px;

          font-size: 14px;
          line-height: 30px;

          &::before {
            content: ' ';

            position: absolute;
            top: 0;
            left: 0;

            width: 6px;
            height: 100%;

            border-radius: 4px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            background-color: transparentize(zef-color($primary, default), 1);

            transition: background-color 500ms ease-out;
          }

          &.selected {
            &::before {
              background-color: zef-color($primary, default);
            }
          }
        }
      }
    }
  }

  // TIMELINE LIST CLASSES

  .zef-timeline-drop {
    position: relative;

    overflow: visible;

    .drop-inner {
      position: absolute;

      pointer-events: none;
      background-color: transparent;
      inset: -20px 0;

      &.active {
        pointer-events: all;
      }

      &::after {
        content: '';

        position: absolute;
        z-index: 2;
        top: 20px;
        right: 0;
        left: 0;

        display: none;
        height: 2px;

        background-color: zef-color($primary, default);
      }

      &.dnd-drag-over {
        &::after {
          display: block;
        }
      }
    }
  }

  .zef-timeline-list {
    &.mat-nav-list {
      .mat-list-item {
        &:hover,
        &.active {
          &.mat-list-item {
            &.zef-timeline-item,
            &.zef-timeline-group,
            &.zef-timeline-header {
              &::before {
                background-color: zef-color($primary, default);

                transition: none;
              }
            }
          }
        }

        &.zef-timeline-item,
        &.zef-timeline-group,
        &.zef-timeline-header {
          &::before {
            content: ' ';

            position: absolute;
            top: 0;
            left: -25px;

            width: 6px;
            height: 100%;

            border-radius: 4px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            background-color: transparentize(zef-color($primary, default), 1);

            transition: background-color 500ms ease-out;
          }
        }

        &.zef-timeline-info,
        &.zef-timeline-item,
        &.zef-timeline-group {
          height: 40px;
          margin: 6px 0;
          border: 1px solid rgba(164, 176, 185, 0.5);
          border-radius: 3px;

          font-size: 14px;
          line-height: 40px;
          color: zef-color($ink, default);
          background-color: #fff;

          .mat-list-item-content {
            height: 40px;

            font-size: 14px;
          }
        }

        &.zef-timeline-header {
          height: 96px;
          margin-top: 12px;
          margin-bottom: 12px;
          border: 1px solid zef-color($space, darkest);
          border-radius: 5px;

          font-size: 16px;
          font-weight: bold;
          background-size: cover;
          background-color: #fff;

          .mat-list-item-content {
            align-items: flex-start;
            height: 100%;
            padding: 12px 16px;
          }
        }

        &.zef-timeline-action {
          height: 40px;
          min-height: 40px;
          margin: 6px 0;

          color: zef-color($ink, lighter);

          .mat-list-item-content {
            height: 40px;

            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }

    .zef-timeline-line {
      position: relative;

      &.line-both,
      &.line-after {
        &::after {
          content: ' ';

          position: absolute;
          bottom: 0;
          left: 28px;

          display: block;
          width: 1px;
          height: 12px;
          margin: -12px 0;
          border-left: solid 2px zef-color($ink, lightest);
        }
      }

      &.line-after {
        &::after {
          height: 6px;
          margin: -6px 0;
        }
      }

      &.line-before {
        &::before {
          content: ' ';

          position: absolute;
          top: 0;
          left: 28px;

          display: block;
          width: 1px;
          height: 12px;
          margin: -6px 0;
          border-left: solid 2px zef-color($ink, lightest);
        }
      }
    }

    .zef-timeline-stopper {
      position: relative;

      width: 2px;
      height: 8px;
      margin: 0 29px 12px;

      background-color: zef-color($ink, lightest);

      &::after {
        content: ' ';

        position: absolute;
        bottom: -5px;
        left: -5px;

        width: 12px;
        height: 5px;
        border-radius: 6px;

        background-color: zef-color($ink, lightest);
      }

      &::before {
        content: ' ';

        position: absolute;
        top: -7px;

        width: 2px;
        height: 8px;
        border-radius: 6px;

        background-color: zef-color($ink, lightest);
      }
    }

    .zef-timeline-ending {
      position: relative;

      width: 2px;
      height: 12px;
      margin: 0 29px 35px;

      background-color: zef-color($ink, lightest);

      &::after {
        content: ' ';

        position: absolute;
        bottom: -5px;
        left: -5px;

        width: 12px;
        height: 12px;
        border-radius: 50%;

        background-color: zef-color($ink, lightest);
      }

      &::before {
        content: ' ';

        position: absolute;
        top: -7px;

        width: 2px;
        height: 8px;
        border-radius: 6px;

        background-color: zef-color($ink, lightest);
      }
    }
  }
}

.zef-tooltip-multiline {
  white-space: pre-line;
  word-break: break-all;
}

.mat-list-base {
  .zef-font-design,
  .zef-color-design {
    &.mat-list-item {
      height: 40px;
      border-radius: 3px;

      &:focus,
      &:hover {
        @include box-shadow-3;
      }

      .mat-list-item-content {
        height: 40px;
      }

      .zef-font-item,
      .zef-color-item {
        position: absolute;

        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 0 16px;
        border-radius: 3px;

        cursor: pointer;
        font-size: 14px;
        line-height: 40px;
        inset: 0;
      }
    }
  }
}

.zef-font-design {
  &.mat-list-item {
    border: 1px solid zef-color($space, darkest);

    background: #fff;
  }
}

.zef-ace-editor {
  &.ace {
    height: 155px;
    min-height: 155px;

    &.error {
      .ace_editor {
        border-color: zef-color($alert, default);
      }
    }

    &.empty {
      .ace_editor {
        .ace_gutter-layer {
          opacity: 0;
        }
      }
    }

    .ace_editor {
      border: 1px solid zef-color($primary, default);
      border-radius: 3px;

      .ace_gutter {
        color: zef-color($ink, default);
        background-color: zef-color($space, dark);

        .ace_gutter-active-line {
          background-color: inherit;
        }
      }
    }
  }
}

.zef-sticky {
  position: sticky;
  top: 0;
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

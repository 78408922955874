@use 'colors' as *;

.zef-border {
  border: 1px solid zef-color($border);
}

.zef-border-transparent {
  border: 1px solid transparent;
}

$palettes: primary, secondary, accent, primary, ink;

@each $palette in $palettes {
  .zef-border-#{$palette} {
    border: 1px solid zef-color(map-get($zef-palettes, $palette));
  }
}

.zef-border-radius-s {
  border-radius: 3px;
}

.zef-border-radius-m {
  border-radius: 5px;
}

.zef-border-radius-l {
  border-radius: 10px;
}

.zef-border-dashed {
  border: 1px dashed zef-color($ink, lightest);
}

.zef-border-dashed-primary {
  border: 1px dashed zef-color($primary);
}

.zef-border-color-primary {
  border-color: zef-color($primary);
}

$directions: top, right, bottom, left;

@each $direction in $directions {
  .zef-border-#{$direction} {
    border-#{$direction}: 1px solid zef-color($border);
  }

  .zef-border-#{$direction}-none {
    border-#{$direction}: none;
  }
}

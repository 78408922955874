@font-face {
  font-style: normal;
  font-weight: normal;
  font-family: 'zef-icons-free';
  font-display: block;
  src: url('fonts/zef-icons-free.woff2?5wahi') format('woff2'), url('fonts/zef-icons-free.ttf?5wahi') format('truetype'),
    url('fonts/zef-icons-free.woff?5wahi') format('woff'), url('fonts/zef-icons-free.svg?5wahi#zef-icons') format('svg');
}

.zef-icons-free {
  speak: none;

  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'zef-icons-free' !important;
  line-height: 1;
  text-transform: none;

  font-feature-settings: 'liga';

  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  font-variant-ligatures: discretionary-ligatures;
}

@use 'colors';

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(10px); }
  75% { transform: translateX(-10px); }
  100% { transform: translateX(0); }
}

.input-error-shake {
  animation: shake 100ms ease-in-out 0ms 3;
}

@keyframes fading {
  0% {
    opacity: 0.03;
  }

  50% {
    opacity: 0.05;
  }

  100% {
    opacity: 0.03;
  }
}

.loading-placeholder {
  opacity: 0.03;
  overflow: hidden;
  min-height: 16px;
  border-radius: 5px;

  background: colors.zef-color(colors.$ink);

  animation: fading 1500ms infinite;
}

@use 'colors' as *;

.zef-tabs-icon {
  display: flex;
  align-self: baseline;
  border: 1px solid zef-color($space, darkest);
  border-radius: 5px;

  .mat-icon-button {
    border: none;
    border-radius: 0 !important;

    &.active {
      background-color: zef-color($space, darker);
      box-shadow: inset 0 1px 2px 0 rgba(zef-color($ink), 0.2);

      .mat-icon {
        color: zef-color($primary, default);
      }
    }

    &:not(:last-child) {
      border-right: 1px solid zef-color($space, darkest);
    }
  }
}

.zef-toggle-swatch {
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  padding: 2px;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;

  cursor: pointer;
  background-color: white;

  &.selected {
    border-color: zef-color($primary, default);
  }

  &:disabled {
    opacity: 0.3;
    border-color: transparent;

    cursor: default;
  }

  figure {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0;
    border-radius: 5px;
  }
}

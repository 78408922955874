@use 'colors';

@media print {
  zef-app {
    display: none;
  }

  * {
    visibility: hidden !important;
  }

  .zef-visual .mat-dialog-container {
    .invoice-wrapper {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;

      overflow: visible;
      min-height: 100%;

      background: colors.zef-color(colors.$secondary);
      contain: strict;

      * {
        visibility: visible !important;
      }

      button {
        display: none;
      }
    }
  }
}

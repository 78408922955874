@use 'colors' as *;
@use 'linda' as *;

@mixin zef-card-color($background, $stroke: false) {
  @if ($stroke) {
    border: 1px solid zef-color(if($background == zef-color($secondary), $space, $background), darkest);
  }

  background-color: $background;
}
@mixin zef-card($background: zef-color($secondary), $stroke: false) {
  @include zef-card-color($background, $stroke);

  overflow: hidden;
  box-sizing: border-box;
  border-radius: 10px;
}

.zef-card-z8 {
  @include zef-card();
  @include linda-shadow(8);
}

.zef-card-z4 {
  @include zef-card();
  @include linda-shadow(4);
}

.zef-card-z4-soft {
  @include zef-card(zef-color($secondary), false);
  @include linda-shadow(4, true);
}

.zef-card-z2 {
  @include zef-card();
  @include linda-shadow(2);
}

.zef-card-z2-soft {
  @include zef-card(zef-color($secondary), false);
  @include linda-shadow(2, true);
}

.zef-card-z1 {
  @include zef-card();
  @include linda-shadow(1);
}

.zef-card-z1-soft {
  @include zef-card(zef-color($secondary), false);
  @include linda-shadow(1, true);
}

.zef-card-z0 {
  @include zef-card();
}

.zef-card-z0-soft {
  @include zef-card(zef-color($secondary), false);
}

.zef-card-accent {
  @include zef-card-color(zef-color($accent));
}

.zef-card-z-1 {
  @include zef-card(zef-color($space));
}

.zef-card-z-1-soft {
  @include zef-card(zef-color($space), false);
}

.zef-card-z-2 {
  @include zef-card(zef-color($space, dark));
}

.zef-card-z-2-soft {
  @include zef-card(zef-color($space, dark), false);
}

.zef-card-z-3-soft {
  @include zef-card(zef-color($space, darker), false);
}

.zef-card-8 {
  padding: 8px;
}

.zef-card-8-16 {
  padding: 8px 16px;
}

.zef-card-16 {
  padding: 16px;
}

.zef-card-24 {
  padding: 24px;
}

.zef-card-32 {
  padding: 32px;
}

.zef-card-32-24 {
  padding: 32px 24px;
}

.zef-card-straight {
  border-radius: 0;
}

.zef-card-ai {
  padding: 1px;
  border-radius: 8px;

  background: zef-color($ai);

  > [class*='zef-card-'] {
    border-radius: 7px;
  }
}

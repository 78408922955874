@use 'colors' as *;
@use 'linda' as *;

.mat-slide-toggle {
  height: 32px;

  &-ripple {
    display: none;
  }

  &-bar {
    width: 32px;

    background-color: zef-color($space, darkest);
  }

  &-thumb {
    box-sizing: border-box;
    border: 2px solid zef-color($ink, lighter);
    border-radius: 10px;

    background: linear-gradient(180deg, zef-color($secondary) 0%, mix(zef-color($secondary), #000, 95%) 100%);

    @include linda-shadow(1);
  }

  &-content {
    font-size: 14px;
    line-height: 22px;
  }

  &.mat-checked {
    @each $palette, $color in getPalettes((primary, warning, alert, promo, logic, success, partner, beta)) {
      &.mat-#{$palette} {
        .mat-slide-toggle {
          &-bar {
            background-color: zef-color($color);
          }

          &-thumb {
            border-color: zef-color($color);
          }
        }
      }
    }

    .mat-slide-toggle {
      &-thumb-container {
        transform: translate3d(12px, 0, 0);
      }
    }
  }

  &.zef-slide-toggle-between {
    width: 100%;

    .mat-slide-toggle-content {
      flex: 1;
    }
  }

  &.zef-text-p2 {
    .mat-slide-toggle-content {
      font-size: 13px;
      line-height: 20px;
    }
  }

  &.zef-toggle-multiline {
    .mat-slide-toggle-content {
      white-space: normal;
    }

    .mat-slide-toggle-bar {
      align-self: baseline;
    }
  }
}

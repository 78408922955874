.zef-dropdown-text {
  width: 100%;
}

[class*='zef-dropdown-list'] {
  padding: 8px 0;

  .mat-divider {
    margin: 8px 0;
  }
}

.zef-dropdown-list-combo {
  .mat-menu-item {
    height: 32px;

    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
  }
}

.zef-dropdown-inline.mat-select {
  width: auto !important;
  border-color: transparent;

  background-color: transparent;

  .mat-select-arrow-wrapper {
    display: inline-block;
    margin-left: 4px;
  }
}

@use 'colors' as *;
@use 'linda' as *;

.survey-status {
  &.small {
    @include linda-survey-status-icon(16);
  }

  &.big,
  &.medium {
    @include linda-survey-status-icon(24);
  }

  &.online::after {
    background-color: zef-color($success);
  }

  &.warning::after {
    background-color: zef-color($warning);
  }

  &.offline::after {
    background-color: zef-color($ink, lightest);
  }

  &.error::after {
    background-color: zef-color($alert);
  }
}

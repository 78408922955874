@use 'colors' as *;
@use 'mixins' as *;
@use 'linda' as *;

.zef-layout-card-24 {
  @include linda-container-card;
  padding: 24px;

  background-color: white;
}

.zef-layout-card-32 {
  @include linda-container-card;
  padding: 32px;
}

.zef-layout-card-header {
  display: block;
  margin-bottom: 24px;
}

.zef-admin-card {
  position: relative;

  overflow: hidden;
  border: 3px dashed zef-color($admin);
  border-radius: 10px;

  background-color: transparentize(zef-color($admin), 0.8);

  .mat-icon.zef-icon-ridiculous {
    position: absolute;
    z-index: 1;
    right: 41px;
    bottom: 24px;

    opacity: 0.2;
    margin: 16px;

    pointer-events: none;
    font-size: 256px;
  }
}

@use 'colors' as *;
@use 'linda' as *;

$palettes: primary, secondary, alert, warning, success, stealth, accent, primary, ink, promo, sms, emails, poll,
  contacts, trigger, responses, logic, link, embed, popup, email-share, facebook, twitter, admin, linkedin, whatsapp,
  youtube, hubspot, salesforce, scoring, group, chart-highlight, choice, mp-choice, beta, partner;

@keyframes button-loading {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 25px 0;
  }
}
@keyframes ghost-button-loading {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 20px 0;
  }
}

.mat-icon-button,
.mat-raised-button {
  position: relative;

  display: inline-flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-height: 32px;
  border: 1px solid rgba(zef-color($ink, default), 0.2);
  outline: none;

  font-weight: 500;
  font-family: Inter, sans-serif;
  box-shadow: none !important;

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::before,
  &::after {
    content: '';

    position: absolute;

    pointer-events: none;

    transition: background-color 75ms ease-out;
    inset: 0;
  }

  &:link,
  &:enabled {
    &:hover {
      &::after {
        background: transparentize(zef-color($secondary), 0.8);
      }
    }

    &:active {
      &::after {
        background: transparentize(zef-color($ink), 0.8);
      }
    }
  }

  &::before {
    // fix for .mat-focus-indicator class added since angular 15
    display: initial;
    border: none;
    border-radius: 0;

    background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.05) 100%);
  }

  $notSelector: '';

  @each $palette in $palettes {
    $notSelector: $notSelector + ':not(.mat-#{$palette})';
  }

  &.mat-transparent,
  &.zef-ghost-button,
  &.zef-transparent-button,
  &#{$notSelector} {
    border-color: transparent;
    border-radius: 8px;

    background: transparent;

    &::before {
      background: transparent;
    }

    &:link,
    &:enabled,
    &:disabled {
      color: zef-color($ink, default);
    }

    &:link,
    &:enabled {
      &:hover,
      &.selected {
        &::after {
          background-color: transparentize(zef-color($ink), 0.95);
        }
      }

      &:active {
        &::after {
          background-color: transparentize(zef-color($ink), 0.9);
        }
      }
    }
  }

  &:disabled {
    opacity: 0.3;

    &.loading {
      opacity: 1;
    }
  }

  &.zef-placeholder-button {
    padding: 16px;
    border: 1px dashed transparent;
    border-radius: 10px;

    font-size: 14px;
    line-height: 16px;

    background: transparent;

    transition: background-color 75ms ease-out;

    .mat-icon {
      width: 32px;
      height: 32px;

      font-size: 32px;
      line-height: 32px;

      + * {
        margin: 16px 0 0;
      }
    }

    .mat-button-wrapper {
      flex-direction: column;
    }

    &::before {
      background: transparent;
    }

    &:link,
    &:enabled {
      &:hover {
        &::after {
          background-color: transparent;
        }
      }

      &:active {
        &::after {
          background-color: transparentize(zef-color($ink), 0.9);
        }
      }
    }

    &.split {
      &-left {
        margin-right: 2px;
        border-radius: 10px 0 0 10px;
      }

      &-right {
        margin-left: 2px;
        border-radius: 0 10px 10px 0;
      }
    }

    &.inline {
      justify-content: start;
      min-width: fit-content;

      .mat-button-wrapper {
        overflow: hidden;
        flex-direction: row;
        justify-content: start;
        padding: 0;

        .mat-icon {
          left: 0;
        }
      }

      &.center {
        justify-content: center;

        .mat-button-wrapper {
          justify-content: center;
        }
      }

      .mat-icon {
        width: 24px;
        height: 24px;

        font-size: 24px;
        line-height: 24px;

        + * {
          margin: 0 0 0 16px;
        }
      }

      &.small {
        font-size: 12px;
        line-height: 14px;

        .mat-icon + * {
          margin: 0 0 0 8px;
        }
      }
    }

    &.small {
      padding: 8px;
    }

    &.thick {
      border-width: 2px;
      border-radius: 16px;
    }
  }

  @each $palette in $palettes {
    $colorMap: map-get($zef-palettes, $palette);
    $color: zef-color($colorMap);
    $contrast: zef-contrast($colorMap);

    &.mat-#{$palette} {
      &:link,
      &:visited,
      &:enabled,
      &:disabled {
        color: $contrast;
        background: $color;
      }

      &.loading {
        pointer-events: none !important;
        background-size: 25px;
        background-color: $color;
        background-image: repeating-linear-gradient(
          90deg,
          transparentize($contrast, 0.9),
          transparentize($contrast, 0.9) 5px,
          $color 6px,
          $color 20px
        ) !important;

        animation: button-loading 400ms linear infinite;
      }

      &.zef-transparent-button,
      &.zef-ghost-button {
        color: $color;

        &.loading {
          background-image: repeating-linear-gradient(
            90deg,
            transparentize($color, 0.9),
            transparentize($color, 0.9) 5px,
            transparentize($color, 0.95) 6px,
            transparentize($color, 0.95) 20px
          ) !important;

          animation-name: ghost-button-loading;
        }
      }

      &.zef-transparent-button {
        &:link,
        &:visited,
        &:enabled,
        &:disabled {
          background: transparent;
        }
      }

      &.zef-ghost-button {
        &:link,
        &:visited,
        &:enabled,
        &:disabled {
          background: transparentize($color, 0.95);
        }
      }

      &.zef-action-button {
        border-color: transparentize($color, 0.1);
        border-radius: 8px;
      }

      &.zef-placeholder-button {
        border-color: $color;

        color: $color;
        background: transparent;

        &.solid {
          background: zef-color($secondary);
        }

        &:link:hover,
        &:visited:hover,
        &:enabled:hover,
        &.filled {
          color: $contrast;
          background: $color;
        }

        &.hover {
          border-color: transparent;

          &:link:hover,
          &:visited:hover,
          &:enabled:hover {
            border-color: $color;

            color: $color;
            background: transparent;
          }

          &:active,
          &:active:hover {
            color: $contrast;
            background: $color;
          }
        }
      }
    }
  }

  &.zef-flat-button {
    border: none;

    &:link,
    &:visited,
    &:enabled,
    &:disabled {
      background: transparentize(zef-color($ink), 0.95);
    }
  }

  &.zef-fab-button {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    @include linda-shadow(4);
  }

  &.zef-fab-teardrop {
    width: 48px;
    height: 48px;
    border-radius: 50% 50% 0;

    transform-origin: bottom right;
    transition: transform 150ms ease;

    @include linda-shadow(4);

    &:not(.active) {
      transform: scale(0.667);
    }
  }
}

.mat-icon-button {
  width: 32px;
  min-width: 32px;
  border-radius: 5px;

  line-height: 32px;
}

.mat-raised-button {
  min-width: 80px;
  padding: 0 14px 0 10px;
  border-radius: 5px;

  font-size: 12px;
  font-weight: 600;
  line-height: 12px;

  .mat-button-wrapper {
    flex: 1;
    padding-left: 4px;

    .mat-icon,
    .zef-button-prefix {
      position: relative;
      left: -4px;
    }
  }

  &.zef-placeholder-button {
    height: auto;
  }

  &.zef-cta-button {
    min-width: 120px;
    min-height: 48px;
    padding: 0 24px;
    border-radius: 10px;

    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;

    &.mat-secondary {
      border: 2px solid zef-color($primary);
    }
  }

  &.zef-action-button {
    border: 2px solid zef-color($ink);
  }

  &.zef-chip-button {
    height: 32px;
    border-radius: 16px;

    font-weight: 400;
  }

  &.zef-dropdown-button {
    padding-right: 6px;
    border-radius: 5px;

    .mat-button-wrapper::after {
      content: 'pointer_down';

      display: inline-flex;
      flex: none;
      align-items: center;
      height: 16px;
      padding-left: 4px;
      margin-left: 8px;
      border-left: 1px solid zef-color($secondary);

      font-size: 24px;
      font-weight: normal;
      font-family: 'zef-icons-full' !important;
    }
  }

  &.zef-stretch-button {
    height: 40px;
    border-radius: 10px;
  }
}

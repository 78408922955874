@use 'linda' as *;

.zef-shadow-1 {
  @include linda-shadow(1);
}

.zef-shadow-2 {
  @include linda-shadow(2);
}

.zef-shadow-4 {
  @include linda-shadow(4);
}

.zef-shadow-8 {
  @include linda-shadow(8);
}

.zef-shadow-16 {
  @include linda-shadow(16);
}

.zef-shadow-soft-1 {
  @include linda-shadow(1, true);
}

.zef-shadow-soft-2 {
  @include linda-shadow(2, true);
}

.zef-shadow-soft-4 {
  @include linda-shadow(4, true);
}

.zef-shadow-soft-8 {
  @include linda-shadow(8, true);
}

.zef-shadow-soft-16 {
  @include linda-shadow(16, true);
}

.zef-shadow-inset-1 {
  @include linda-shadow(-1);
}

.zef-text-shadow-fog {
  text-shadow: 0 0 2px white;
}

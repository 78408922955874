@use 'colors' as *;

.zef-interactive {
  position: relative;

  overflow: hidden;
  outline: none;

  cursor: pointer;

  &:hover,
  &:active,
  &.selected,
  &.cdk-option-selected,
  &.cdk-option-active {
    &::after {
      content: '';
      position: absolute;

      pointer-events: none;
      inset: 0;
    }
  }

  &:hover::after {
    background-color: transparentize(zef-color($ink), 0.95);
  }

  &:active,
  &.cdk-option-active {
    &::after {
      background-color: transparentize(zef-color($ink), 0.9);
    }
  }

  &.selected,
  &.cdk-option-selected {
    pointer-events: none;

    &::after {
      background-color: transparentize(zef-color($primary), 0.95);
    }
  }
}

.zef-pointer-events-none {
  pointer-events: none !important;
}

.zef-invisible {
  visibility: hidden;
}

.zef-cursor-pointer {
  cursor: pointer;
}

@use 'colors' as *;
@use 'linda' as *;

.survey-status {
  box-sizing: border-box;
  flex: 0 0 auto;

  transform: translateY(4px);

  &.online {
    @include linda-survey-status-icon(24, zef-color($success));
  }

  &.warning {
    @include linda-survey-status-icon(24, zef-color($warning));
  }

  &.offline {
    @include linda-survey-status-icon(24, zef-color($ink, lightest));
  }

  &.error {
    @include linda-survey-status-icon(24, zef-color($alert));
  }

  &.inline {
    transform: scale(0.5);
  }

  &.toggle {
    transform: scale(0.6667);
  }
}

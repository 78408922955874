@use 'colors' as *;

.shepherd-modal-overlay-container {
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  fill: rgba(0, 0, 0, 0.3);
}

.shepherd-cancel-icon {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin: 8px;
  border: none;

  cursor: pointer;
  font-size: 40px;
  color: zef-color($ink, lightest);
  background: transparent;
}

.shepherd-title {
  max-width: calc(100% - 32px);
  margin-top: 8px;

  font-size: 18px;
  font-weight: 500;
  font-family: Inter, sans-serif;
  line-height: 24px;
}

.shepherd-text {
  font-size: 14px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  line-height: 22px;
}

.shepherd-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  .shepherd-button + .shepherd-button {
    margin-left: 8px;
  }
}

.tour-container {
  z-index: 1001;

  width: 400px;
  padding: 16px;
  outline: none;

  background-color: zef-color($space);
}

.tour-highlight {
  z-index: 1001;

  padding: 4px;
  margin: -4px;
}

.tour-dialog {
  &-top {
    transform: translateY(-8px);
  }

  &-right {
    transform: translateX(8px);
  }

  &-bottom {
    transform: translateY(8px);
  }

  &-left {
    transform: translateY(-8px);
  }
}

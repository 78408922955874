@use 'linda' as *;

.z-linda-user-tile {
  @include linda-container-card;

  display: grid;
  padding: 16px 24px 16px 16px;

  grid-column-gap: 16px;
  grid-template-areas:
    'logo name'
    'logo email';
  grid-template-columns: 48px auto;

  figure {
    @include linda-user-avatar(48);
    grid-area: logo;
  }

  strong {
    grid-area: name;
  }

  span {
    grid-area: email;
  }
}

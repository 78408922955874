@use 'colors' as *;

.zef-avatar-team,
.zef-avatar-user {
  position: relative;
  // margin: 48px 0 32px;

  background: linear-gradient(180deg, rgba(white, 0) 0%, rgba(black, 0.2) 100%);
  background-color: #dae2e5;
  box-shadow: 0 4px 8px 0 rgba(zef-color($ink), 0.2);

  zef-image-upload {
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;

    background-color: white;
  }
}

.zef-avatar-team {
  width: 192px;
  height: 192px;
  margin: 48px 0 32px;
  border-radius: 48px;

  .zef-avatar-image,
  zef-image-upload {
    width: 178px;
    height: 178px;
    border-radius: 40px;
  }

  .zef-avatar-image {
    max-width: none;
    max-height: none;

    background-color: white;
  }
}

.zef-avatar-user {
  width: 160px;
  height: 160px;
  margin: 48px 0 32px;
  border-radius: 50%;

  zef-image-upload {
    width: 144px;
    height: 144px;
    border-radius: 50%;
  }
}

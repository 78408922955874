/* You can add global styles to this file, and also import other style files */

@use 'sass:meta';

@use 'colors' as *;

// @import 'tailwindcss/base';
// @import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@include meta.load-css('main');
@include meta.load-css('./assets/flags');
@include meta.load-css('./assets/icons/style-free');
@include meta.load-css('./assets/icons/style-full');
@include meta.load-css('quill/dist/quill.snow.css');

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;

  font-family: Inter, sans-serif;
  color: zef-color($ink, default);
  background-color: zef-color($space, default);
}

input,
textarea {
  box-sizing: border-box;

  outline: none;

  resize: none;
  font-family: inherit;
  color: inherit;
}

*:-moz-placeholder {
  font-weight: lighter;
}

*::-moz-placeholder {
  font-weight: lighter;
}

*:-ms-input-placeholder {
  font-weight: lighter;
}

*::-webkit-input-placeholder {
  font-weight: lighter;
}

.layout .content .zef-visual auth-app {
  // for auth-app in website
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;

  height: 100vh;

  .mat-drawer-container {
    background-color: transparent;
  }

  .mat-drawer {
    max-width: 424px;
  }
}

@use 'colors' as *;

.zef-pattern {
  &-archive {
    $color: rgba(zef-color($ink, lighter), 0.05);

    background-image: repeating-linear-gradient(-45deg, transparent, transparent 4px, $color 4px, $color 6px);
  }

  &-warning {
    $color: rgba(zef-color($warning), 0.2);

    background-image: repeating-linear-gradient(45deg, transparent, transparent 6px, $color 6px, $color 10px);
  }

  &-alpha {
    $color: rgba(zef-color($ink, lighter), 0.1);

    background-size: 8px 8px;

    background-image:
      linear-gradient(45deg, $color 25%, transparent 25%),
      linear-gradient(-45deg, $color 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, $color 75%),
      linear-gradient(-45deg, transparent 75%, $color 75%);
    background-position: 0 0, 0 4px, 4px -4px, -4px 0;
  }
}

@use 'colors' as *;

.mat-divider {
  border-top-color: zef-color($space, darkest);

  &.zef-divider-contrast {
    border-top-color: zef-color($secondary);
  }

  &.zef-divider-thick {
    border-top-width: 8px;
    border-top-color: transparentize(zef-color($ink), 0.9);
  }

  &.zef-offset-16 {
    width: calc(100% + 16px);
  }

  &.zef-offset-24 {
    width: calc(100% + 24px);
  }

  &.zef-offset-24-24 {
    width: calc(100% + 48px);

    transform: translateX(-24px);
  }

  &.zef-offset-32 {
    width: calc(100% + 32px);
  }

  &-vertical {
    border-right-color: zef-color($space, darkest);

    &.zef-divider-contrast {
      border-right-color: zef-color($secondary);
    }

    &.zef-divider-8 {
      height: 8px;
    }

    &.zef-divider-16 {
      height: 16px;
    }

    &.zef-divider-24 {
      height: 24px;
    }

    &.zef-divider-32 {
      height: 32px;
    }

    &.zef-divider-segment-4 {
      margin-right: 4px;
      margin-left: 4px;
    }

    &.zef-divider-segment-8 {
      margin-right: 8px;
      margin-left: 8px;
    }

    &.zef-divider-segment-16 {
      margin-right: 16px;
      margin-left: 16px;
    }
  }

  &-horizontal {
    &.zef-divider-16 {
      width: 16px;
    }

    &.zef-divider-24 {
      width: 24px;
    }

    &.zef-divider-32 {
      width: 32px;
    }

    &.zef-divider-segment-8 {
      width: calc(100% - 16px);
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.zef-divider-text {
  display: flex;
  align-items: center;
  width: 100%;

  &:not(.zef-divider-small) {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
  }

  &::before,
  &::after {
    content: '';

    flex: 1;
    height: 1px;

    background-color: zef-color($border);
  }

  &::before {
    margin-right: 16px;
  }

  &::after {
    margin-left: 16px;
  }
}

@use 'colors' as *;

.zef-highlight {
  position: relative;

  overflow: hidden;
  box-sizing: border-box;
  padding: 16px 16px 16px 20px;
  border-radius: 10px;

  font-size: 14px;
  line-height: 22px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 4px;
  }

  &.mat-primary {
    border-color: zef-color($primary, default);

    color: zef-color($primary, dark);
    background-color: zef-color($primary, lighter);

    &::after {
      background-color: zef-color($primary);
    }
  }

  &.mat-success {
    border-color: zef-color($success, default);

    color: zef-color($success, dark);
    background-color: zef-color($success, lighter);

    &::after {
      background-color: zef-color($success);
    }
  }

  &.mat-warning {
    border-color: zef-color($warning, default);

    color: zef-color($warning, darker);
    background-color: zef-color($warning, lighter);

    &::after {
      background-color: zef-color($warning);
    }
  }

  &.mat-alert {
    border-color: zef-color($alert, default);

    color: zef-color($alert, dark);
    background-color: zef-color($alert, lighter);

    &::after {
      background-color: zef-color($alert);
    }
  }

  &.zef-info {
    color: zef-color($ink, lighter);
    background-color: #fcfdfd;

    &::after {
      background-color: zef-color($ink, lightest);
    }
  }

  &.zef-codeblock {
    padding: 16px;
    border: 1px solid zef-color($space, darkest);

    font-family: monospace;
    background-color: zef-color($space, default);

    &::after {
      content: none;
    }
  }
}

@use 'sass:meta';

@include meta.load-css('print');
@include meta.load-css('common');
@include meta.load-css('visual');

.zef-visual {
  @include meta.load-css('language/color-classes');
  @include meta.load-css('language/shadow-classes');
  @include meta.load-css('language/text-classes');
  @include meta.load-css('language/area-classes');
  @include meta.load-css('language/card-classes');
  @include meta.load-css('language/interaction-classes');
  @include meta.load-css('language/animation-classes');
  @include meta.load-css('language/border-classes');

  @include meta.load-css('elements/buttons');
  @include meta.load-css('elements/divider');
  @include meta.load-css('elements/scrollbar');
  @include meta.load-css('elements/checkbox');
  @include meta.load-css('elements/radio');
  @include meta.load-css('elements/toggle');
  @include meta.load-css('elements/chip');
  @include meta.load-css('elements/status');
  @include meta.load-css('elements/button-toggle');

  @include meta.load-css('components/avatar');
  @include meta.load-css('components/calendar');
  @include meta.load-css('components/slider');
  @include meta.load-css('components/tooltip');
  @include meta.load-css('components/quill');
  @include meta.load-css('components/shepherd');

  @include meta.load-css('layout/card');
  @include meta.load-css('layout/page');
  @include meta.load-css('layout/planks');
  @include meta.load-css('layout/sidenav');

  @include meta.load-css('templates/dropdown');
  @include meta.load-css('templates/highlight');
  @include meta.load-css('templates/grids');
  @include meta.load-css('templates/icons');
  @include meta.load-css('templates/input');
  @include meta.load-css('templates/menu');
  @include meta.load-css('templates/patterns');
  @include meta.load-css('templates/tabs');
  @include meta.load-css('templates/tiles');
}

@include meta.load-css('flex-grid');
@include meta.load-css('auth');

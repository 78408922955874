@use 'colors' as *;

@mixin cellContentBorder($color) {
  .mat-calendar-body-cell-content {
    border: 2px solid transparentize(zef-color($color), 0.5) !important;

    background-color: transparent !important;
  }
}
@mixin cellContentBackground($color) {
  .mat-calendar-body-cell-content {
    color: zef-contrast($color) !important;
    background-color: zef-color($color) !important;
  }
}
@mixin cellContentRange($color) {
  .mat-calendar-body-cell-content {
    background-color: transparentize(zef-color($color), 0.9) !important;
  }
}

.mat-calendar {
  display: flex;
  flex-direction: column;
  width: 272px;
  min-height: 350px;

  &.zef-date-range .mat-calendar-body-cell-container {
    &.range {
      &-hover {
        &-from {
          @include cellContentBorder($success);
        }

        &-until {
          @include cellContentBorder($primary);
        }
      }

      &-selected {
        &-from {
          @include cellContentBackground($success);

          &-until {
            @include cellContentBackground($logic);
          }
        }

        &-until {
          @include cellContentBackground($primary);
        }
      }

      &-from {
        @include cellContentRange($success);

        &-until {
          @include cellContentRange($logic);
        }
      }

      &-until {
        @include cellContentRange($primary);
      }
    }
  }

  &-table {
    height: 100%;

    &-header {
      &-divider {
        display: none;
      }

      th {
        // firefox fix
        height: 1px;
        padding: 8px 0 4px;

        color: zef-color($ink, lighter);
      }

      tr + tr {
        display: none;
      }
    }
  }

  &-content {
    flex: 1;
    padding: 0 8px 8px 4px;
  }

  &-body {
    font-size: 14px;

    > tr[aria-hidden='true']:first-child {
      display: none;
    }

    &-label {
      visibility: hidden;
      padding: 0 !important;
    }

    &-disabled {
      pointer-events: none;

      .mat-calendar-body-cell-content {
        color: transparentize(zef-color($ink), 0.7) !important;
        background-color: transparent;
      }
    }

    &-cell {
      &-container {
        width: 32px !important;
        height: 32px !important;
        padding: 2px !important;
      }

      &-content {
        position: relative;

        width: 32px;
        height: 32px;
        border-radius: 10px;

        background-color: transparent;
      }

      .mat-calendar-body-today {
        border: 2px solid zef-color($stealth, dark) !important;

        color: zef-color($ink) !important;
        background-color: zef-color($secondary) !important;
      }

      .mat-calendar-body-selected {
        color: zef-color($secondary) !important;
        background-color: zef-color($primary) !important;
      }

      &.mat-calendar-body-other {
        .mat-calendar-body-cell-content {
          color: zef-color($success) !important;
        }
      }
    }
  }
}

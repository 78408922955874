@use 'colors' as *;
@use 'linda' as *;

.zef-tabs-icon {
  display: flex;
  align-self: baseline;
  border: 1px solid zef-color($space, darkest);
  border-radius: 5px;

  .mat-icon-button {
    border: none;
    border-radius: 0 !important;

    &.active {
      background-color: zef-color($space, darker);
      box-shadow: inset 0 1px 2px 0 rgba(zef-color($ink), 0.2);

      .mat-icon {
        color: zef-color($primary);
      }
    }

    &:not(:last-child) {
      border-right: 1px solid zef-color($space, darkest);
    }
  }
}

.mat-tab-nav-bar.zef-tabs-floating-icon,
.mat-tab-nav-bar.zef-tabs-floating,
.mat-tab-group.zef-tabs-floating,
.mat-tab-group.zef-tabs-floating-icon {
  border-radius: 8px;

  background: transparentize(zef-color($ink), 0.95) !important;

  .mat-tab-header {
    border-bottom: 0;
  }

  .mat-tab-list {
    .mat-tab-links .mat-tab-link,
    .mat-tab-labels .mat-tab-label {
      opacity: 1;
      flex: 1;
      min-width: 0;
      padding: 8px;
      border: none;
      border-radius: 8px;

      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      background: transparent;
      box-shadow: none;

      &.mat-tab-label-active {
        background-color: zef-color($secondary);

        @include linda-shadow(1, true);
      }
    }
  }
}

.mat-tab-nav-bar.zef-tabs-floating-icon,
.mat-tab-group.zef-tabs-floating-icon {
  .mat-tab-list {
    .mat-tab-links .mat-tab-link,
    .mat-tab-labels .mat-tab-label {
      min-width: 32px;
      padding: 4px;
    }
  }
}

.zef-toggle-swatch {
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  padding: 2px;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;

  cursor: pointer;
  background-color: white;

  &.selected {
    border-color: zef-color($primary, default);
  }

  &:disabled {
    opacity: 0.3;
    border-color: transparent;

    cursor: default;
  }

  figure {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0;
    border-radius: 5px;
  }
}

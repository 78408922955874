@use 'linda' as *;
@use 'colors' as *;

.quill,
quill-editor {
  width: 100%;

  .ql-editor {
    outline: none !important;
    overflow-y: initial;
  }

  &.big {
    .ql-editor {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &.small {
    .ql-editor {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

quill-editor.zef-quill {
  @include linda-shadow(-1);

  border: 1px solid zef-color($space, darkest);
  border-radius: 5px;

  background-color: zef-color($secondary);

  &.space {
    background-color: zef-color($space);
  }

  &:hover {
    border: 1px solid zef-color($ink, lightest);
  }

  &:active {
    border: 1px solid zef-color($primary);
  }

  .ql-editor {
    font-size: 14px;
  }

  .ql-snow {
    &.ql-toolbar {
      @include linda-shadow(4);

      margin: 8px;
      border: 0;
      border-radius: 5px;

      background-color: zef-color($secondary);

      .ql-formats + .ql-formats {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 4px;
          left: -4px;

          width: 1px;
          height: 16px;

          background-color: zef-color($border);
        }
      }

      button.ql-active,
      button:hover {
        color: zef-color($primary);
      }
    }

    &.ql-container {
      min-height: 200px;
      border: 0;
    }

    .ql-editor {
      min-height: 200px;
      padding: 16px;

      .ql-video {
        height: 386px;
      }
    }

    .ql-tooltip {
      @include linda-shadow(4);

      left: 8px !important;

      border: 0;
      border-radius: 5px;
    }

    .ql-picker.ql-size {
      width: 60px;

      .ql-picker-label,
      .ql-picker-item {
        ::before {
          content: '14px';
        }

        &[data-value='12px']::before {
          content: '12px';
        }

        &[data-value='14px']::before {
          content: '14px';
        }

        &[data-value='18px']::before {
          content: '18px';
        }

        &[data-value='24px']::before {
          content: '24px';
        }
      }
    }
  }
}

@use 'colors' as *;

$items: success, logic, warning, alert, primary, trigger;

.mat-menu-panel {
  .mat-menu-content {
    .mat-menu-item {
      @each $item in $items {
        &.zef-menu-item-#{$item} {
          color: zef-color(map-get($zef-palettes, $item), if($item == warning, dark, default));

          .mat-icon {
            color: zef-color(map-get($zef-palettes, $item), if($item == warning, dark, default));
          }

          &:hover:not([disabled]) {
            .mat-icon {
              color: zef-contrast(map-get($zef-palettes, $item), if($item == warning, dark, default));
            }

            background-color: zef-color(map-get($zef-palettes, $item), if($item == warning, dark, default));
          }
        }
      }

      zef-help-icon {
        margin-right: -16px;
      }
    }
  }

  &.zef-menu-offset-y-16 {
    position: relative;

    &.mat-menu-above {
      top: -16px;
    }

    &.mat-menu-below {
      bottom: -16px;
    }
  }
}

.zef-menu-combo.mat-menu-panel {
  min-width: 200px;
  border-radius: 10px !important;

  .mat-menu-content {
    padding: 8px 0;
  }

  .mat-divider {
    margin: 8px 0;
  }

  .mat-menu-item {
    display: flex;
    align-items: center;
    height: 32px;

    line-height: 32px;

    .mat-icon,
    .zef-menu-item-prefix {
      margin-right: 8px;

      color: inherit;
    }

    &[disabled] {
      color: zef-color($ink, lightest);
    }
  }

  &.zef-menu-persistent {
    margin: 8px 0;

    zef-help-icon {
      margin-right: -8px;
    }
  }
}

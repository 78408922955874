.zef-layout-planks {
  > * {
    border-radius: 0;
  }

  > :first-child {
    border-radius: 10px 10px 0 0;
  }

  > :not(:last-child) {
    margin-bottom: 2px;
  }

  > :last-child {
    border-radius: 0 0 10px 10px;
  }
}

@use 'linda' as *;
@use 'colors' as *;

.zef-chip-small {
  @include linda-chip('small');
}

.zef-chip-medium {
  @include linda-chip('medium');
}

.zef-chip-large {
  @include linda-chip('large');
}

.zef-chip-property {
  @include linda-chip('larger');
  background-color: zef-color($primary, light);
}

.zef-chip-hashtag {
  @include linda-chip('larger');
  background-color: zef-color($space, darkest);
}

.zef-chip-owner {
  @include linda-chip('small');
  color: white;
  background-color: zef-color($success, dark);
}

.zef-chip-member {
  @include linda-chip('small');
  color: white;
  background-color: zef-color($primary, dark);
}

.zef-chip-admin {
  @include linda-chip('small');
  color: white;
  background-color: zef-color($warning, dark);
}

.zef-chip-field {
  &.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
    height: 100%;
  }
}

.zef-chip-beta {
  @include linda-chip('small');

  height: 20px !important;

  color: white;
  background-color: #f65aa5;
}
